import { baseUrl } from "../baseUrl";

export const getAllCategory = async () => {
  try {
    const response = await fetch(`${baseUrl}/checkCategories`);
    const result = response.json();
    return result;
  } catch (error) {
    throw new Error({ message: error.message });
  }
};

export const getCategoryProducts = async (slug) => {
  try {
    const response = await fetch(`${baseUrl}/category/${slug}`);
    const result = response.json();
    return result;
  } catch (error) {
    throw new Error({ message: error.message });
  }
};

export const getAll = async () => {
  try {
    const response = await fetch(`${baseUrl}/check`);
    const result = response.json();
    return result;
  } catch (error) {
    throw new Error({ message: error.message });
  }
};

export const getOneProduct = async (productID) => {
  try {
    const response = await fetch(`${baseUrl}/check/${productID}`);
    const result = response.json();
    return result;
  } catch (error) {
    throw new Error({ message: error.message });
  }
};
