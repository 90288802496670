import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import { setProducts } from "./store/slices/product-slice"
import products from "./data/products.json";
import 'animate.css';
import 'swiper/swiper-bundle.min.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import "./i18n";
import * as productService from './services/ProductService'

const STORAGE_KEY = "products";
const UPDATE_INTERVAL_MS = 10 * 60 * 1000; // 10 minutes in milliseconds

async function fetchData() {
  try {
    const products = await productService.getAll();
    return products;
  } catch (error) {
    console.error(error);
    return [];
  }
}



function updateSessionStorageWithProducts(products) {
  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(products));
}

function startBackgroundDataSync() {
  setInterval(fetchData, UPDATE_INTERVAL_MS);
}

// Check if products are stored in sessionStorage
const storedProducts = sessionStorage.getItem(STORAGE_KEY);

if (storedProducts) {
  const parsedProducts = JSON.parse(storedProducts);
  store.dispatch(setProducts(parsedProducts));
} else {
  fetchData().then((result) => {
    
    if (Object.values(result.products).length > 0) {
      store.dispatch(setProducts(Object.values(result.products)));
      updateSessionStorageWithProducts(Object.values(result.products));
    } else {
      console.log("No products fetched. Rendering not performed.");
    }
  });
  setTimeout(fetchData, 1000);
}
fetchData();
startBackgroundDataSync();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
      <PersistProvider>
        <App />
      </PersistProvider>
    </Provider>
);

